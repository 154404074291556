/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-20",
    versionChannel: "nightly",
    buildDate: "2024-02-20T00:05:35.428Z",
    commitHash: "30a08643296d3910593ec92d082118a5edf2269e",
};
